import React from 'react';
import { Context } from './Context';
export default class Scrollable extends React.Component {
	state = { x: 0, y: 0 };

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll = (ev) => {
		this.setState({
			x: window.scrollX,
			y: window.scrollY
		});
	};

	render() {
		const { children } = this.props;
		return (
			<div>
				<Context.Provider value={{ y: this.state.y }}>{children}</Context.Provider>
			</div>
		);
	}
}
