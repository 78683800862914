//@ts-check
import React from 'react';
import { Link } from 'gatsby';
const Footer = ({ sites }) => (
	<div className="bg-black-us">
		<div className="flex justify-center items-center pt-20 px-3">
			<div className="mx-auto">
				{sites.map((s) => (
					<Link
						key={s.text}
						className="block sm:inline-block md:inline-block py-4 px-4 uppercase no-underline font-normal text-center text-white "
						to={s.path}
					>
						{s.text}
					</Link>
				))}
			</div>
		</div>
		<div className="flex justify-center items-center py-10 px-3 text-sm text-grey-light">
			Copyright © 2018 - {new Date().getFullYear()} Unbreakable Studio. All Rights Reserved.
		</div>
	</div>
);

export default Footer;
