import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import "../css/add.css";
import { Context } from "./Context";

const handleClick = ev => {
  ev.preventDefault();
  const element = document.getElementById("nav");
  const sanwich = document.getElementById("sanwich");
  const cross = document.getElementById("cross");

  element.classList.toggle("block");
  element.classList.toggle("hidden");

  sanwich.classList.toggle("block");
  sanwich.classList.toggle("hidden");

  cross.classList.toggle("block");
  cross.classList.toggle("hidden");
};

const TituloGrande = ({ siteTitle, y }) => (
  <Link to="/" className=" hidden md:flex items-center no-underline text-blue">
    <span
      className={`p-1 tracking-wide font-bold text-2xl underline--magical ${
        y > 0 ? `applyMagic` : ``
      }`}
    >
      {siteTitle.toUpperCase()}
    </span>
  </Link>
);

const TituloPeque = ({ shortTitle, y }) => (
  <Link
    to="/"
    className="block md:hidden flex items-center no-underline text-blue"
  >
    <span
      className={`p-1 tracking-wide font-bold text-2xl underline--magical ${
        y > 0 ? `applyMagic` : ``
      }`}
    >
      {shortTitle.toUpperCase()}
    </span>
  </Link>
);

const LinksPeque = ({ sites }) => (
  <React.Fragment>
    <button
      id="icon"
      className="block md:hidden flex items-center px-3 py-2 rounded text-black"
      onClick={handleClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        height="32px"
        id="sanwich"
        className="fill-current"
        version="1.1"
        viewBox="0 0 32 32"
        width="32px"
        xmlSpace="preserve"
      >
        <title>Menu</title>
        <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z" />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        height="32px"
        className="hidden fill-current"
        id="cross"
        version="1.1"
        viewBox="0 0 32 32"
        width="32px"
        xmlSpace="preserve"
      >
        <title>Cross</title>
        <line x1="1" y1="30" x2="30" y2="1" stroke="black" strokeWidth="4" />
        <line
          x1="1"
          y1="1"
          x2="30"
          y2="30"
          stroke="black"
          strokeWidth="4"
        />{" "}
      </svg>
    </button>

    {/* Small */}
    <div
      onClick={handleClick}
      id="nav"
      className="hidden lg:hidden md:hidden xl:hidden md:items-center w-full md:w-auto relative transition-slower"
    >
      <div className="text-sm absolute">
        {sites.map(s => (
          <Link
            key={s.text}
            className="uppercase block mt-2 no-underline text-white text-lg bg-black-us text-white text-center p-6 w-screen-85"
            to={s.path}
          >
            {s.text}
          </Link>
        ))}
      </div>
    </div>
  </React.Fragment>
);

const LinksGrande = ({ sites }) => {
  return (
    <div className=" hidden md:flex md:items-center w-full md:w-auto">
      {/* Big */}
      <div className="text-sm">
        {sites.map(s => (
          <Link
            key={s.text}
            className="p-1 uppercase link-nav pt-5 md:pt-0 lg:pt-0 xl:pt-0 block mt-4 md:inline-block md:mt-0 mr-8 no-underline transition-fast text-black text-lg text-center"
            to={s.path}
          >
            {s.text}
          </Link>
        ))}
      </div>
    </div>
  );
};

class Header extends React.Component {
  static contextType = Context;

  render() {
    const { siteTitle, sites, shortTitle } = this.props;
    const { y } = this.context;

    return (
      <nav className={`${y > 0 ? "navnav" : ""} points`}>
        <div className="flex flex-wrap items-center justify-between p-6">
          <TituloGrande y={y} siteTitle={siteTitle} />

          <TituloPeque y={y} shortTitle={shortTitle} />

          <LinksPeque sites={sites} />

          <LinksGrande sites={sites} />
        </div>
      </nav>
    );
  }
}
Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
