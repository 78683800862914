import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import PageTransition from 'gatsby-v2-plugin-page-transitions';
import Header from './header';
import '../css/style.css';
import '../css/add.css';
import Scrollable from './Scrollable';
import { Context } from './Context';
import Footer from './Footer';
import SEO from './seo';

class Layout extends React.Component {
	render() {
		const { children } = this.props;

		return (
			<StaticQuery
				query={graphql`
					query SiteTitleQuery {
						site {
							siteMetadata {
								title
								shortTitle
								sites {
									text
									path
								}
							}
						}
					}
				`}
				render={({ site: { siteMetadata: { title, sites, shortTitle } } }) => (
					<Scrollable>
						<SEO
							title="Contact"
							keywords={[
								`websites`,
								`webapps`,
								`webapps guatemala`,
								`react`,
								`unbreakable`,
								`studio`,
								`serverless`,
								`guatemala`,
								`node`,
								`node guatemala`,
								`serverless guatemala`,
								`go guatemala`,
								`react guatemala`,
								`gatsby guatemala`
							]}
						/>

						<div className="flex flex-col font-sans min-h-screen text-grey-darkest transition-slow points">
							<Header siteTitle={title} sites={sites} shortTitle={shortTitle} />
							<PageTransition>
								<Context.Consumer>
									{({ y }) => (
										<React.Fragment>
											{y > 0 ? <div className="bg-white h-24" /> : null}
											{children}
										</React.Fragment>
									)}
								</Context.Consumer>
							</PageTransition>
							<Footer sites={sites} />
						</div>
					</Scrollable>
				)}
			/>
		);
	}
}

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
